export const isNumeric = (value: string): boolean => /^-?\d+$/.test(value);
export const trimLink = (link: string): string => link.replace(/([^:]\/)\/+/g, "$1");

export const pluralize = (word: string, count: number): string => {
    if (count === 1) {
        return word;
    }

    const irregularPlurals: { [key: string]: string } = {
        person: "people",
        child: "children",
        man: "men",
        woman: "women",
        mouse: "mice",
        goose: "geese",
        tooth: "teeth",
        foot: "feet",
    };

    if (irregularPlurals[word]) {
        return irregularPlurals[word];
    }

    // Handle pluralization for words ending in 'y' (but not with vowels before 'y')
    if (word.endsWith("y") && !/[aeiou]y$/.test(word)) {
        return `${word.slice(0, -1)}ies`;
    }

    // Handle words that end in 's', 'x', 'z', 'ch', or 'sh'
    if (/s$|x$|z$|ch$|sh$/.test(word)) {
        return `${word}es`;
    }

    return `${word}s`;
};
