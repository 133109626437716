import { IUser, IUserListingCredits } from "app/store/types/user.types";

type ListingCreditKeys = keyof IUserListingCredits;
type UserReturnType = {
    listingCredits: (type: ListingCreditKeys) => number;
    hasListingCredits: () => boolean;
    isPlanUpgradable: () => boolean;
    isPremiumUser: () => boolean;
    isVendor: boolean;
    isStaff: boolean;
    renewalDate: () => string | null;
    sponsorFinder: () => { leads: number | undefined };
    planDisplayName: () => string;
};

const User = (user: IUser): UserReturnType => {
    const isPlanUpgradable = (): boolean => user?.plan?.upgradeable;
    const isPremiumUser = (): boolean => user?.plan?.name === "premium";
    const isStaff = user?.is_staff;
    const planDisplayName = (): string => user?.plan?.display_name;
    const listingCredits = (type: ListingCreditKeys): number => user?.listing_credits[type];
    const hasListingCredits = (): boolean => user?.has_listing_credits;
    const renewalDate = (): string | null => user?.plan?.renewal_date;
    const isVendor = user?.is_vendor;
    const sponsorFinder = (): { leads: number | undefined } => ({
        leads:
            user?.plan?.sponsor_finder?.leads > 0 ? user?.plan?.sponsor_finder?.leads : undefined,
    });

    return {
        isVendor,
        isStaff,
        listingCredits,
        hasListingCredits,
        isPlanUpgradable,
        isPremiumUser,
        renewalDate,
        sponsorFinder,
        planDisplayName,
    };
};

export default User;
