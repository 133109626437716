import API_URL from "app/constants/api_urls";
import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import API from "../axios";

const bulkdMarkRead = async (payload: { quotes: (string | number)[] }): Promise<any> => {
    const res = await API.put<any>(`${API_URL.QUOTES}mark_read/`, { quotes: payload.quotes });

    return res.data;
};

export const useBulkdMarkRead = ({
    onSuccess,
    onError,
}: {
    onSuccess?: () => void;
    onError?: (error: AxiosError<{ message: string }>) => void;
}) => {
    const queryClient = useQueryClient();

    return useMutation<
        any,
        AxiosError<{ message: string }>,
        { quotes: (string | number)[]; id: number; page: number; quoteType: string | null }
    >({
        mutationFn: (payload) => bulkdMarkRead(payload),
        onSuccess: (data, { id, page, quoteType }) => {
            if (onSuccess) {
                onSuccess();
            }

            queryClient.invalidateQueries({
                queryKey: ["vendor_quote_requests", id, page, quoteType],
            });

            queryClient.invalidateQueries({
                queryKey: ["vendor_quote_requests_count", id],
            });
        },
        onError: (error) => {
            if (onError) {
                onError(error);
            }
        },
    });
};
