import { object, string, number } from "yup";

const validationMessages = {
    required: "This field is required",
};

export const addReviewValidation = object().shape({
    content: string()
        .required(validationMessages.required)
        .min(100, "Please help others by adding a few more words to your review"),

    rating: number().required(validationMessages.required).min(1).max(5),
});
