import { useQuery, keepPreviousData } from "@tanstack/react-query";
import { UseQueryReturnType, UseQueryOptionsType } from "app/utils/api/queries";
import { ListingQuoteRequestType } from "app/types";
import API from "app/utils/api/axios";
import API_URL from "app/constants/api_urls";
import { QuoteType } from "app/views/protected/business_dashboard/quote_requests/quote_requests_filters";
import { AxiosError } from "axios";

export type ReturnType = {
    count: number;
    next: number | null;
    previous: number | null;
    page_size: number;
    results: ListingQuoteRequestType[];
};

const useQuoteRequests = (
    id: number | undefined,
    page?: number | undefined,
    quoteType?: QuoteType | null | string,
    options?: UseQueryOptionsType
): UseQueryReturnType<ReturnType> => {
    const url = `${API_URL.BUSINESS_QUOTE_REQUESTS}/${id}/quotes/`;
    const params = new URLSearchParams();
    if (page) params.append("page", page.toString());
    if (quoteType) params.append("display", quoteType.toString());
    const queryString = params.toString();
    const baseUrl = queryString ? `${url}?${queryString}` : url;

    const { isPending, isError, data, error, isPlaceholderData, isLoading } = useQuery({
        placeholderData: keepPreviousData,
        queryKey: ["vendor_quote_requests", id, page, quoteType],
        queryFn: async ({ signal }) => {
            const { data } = await API.get(baseUrl, {
                signal,
            });
            return data;
        },
        ...options,
    });

    return {
        data,
        isLoading: isPending && isLoading,
        isPlaceholderData,
        isError,
        error,
    };
};

const useQuoteRequestsCount = (
    id: number | undefined,
    viewed?: boolean,
    options?: UseQueryOptionsType
): UseQueryReturnType<number> => {
    const url = `${API_URL.BUSINESS_QUOTE_REQUESTS}/${id}/quotes/count/?viewed=${viewed}`;

    const { isLoading, isError, data, error, isPlaceholderData } = useQuery({
        placeholderData: keepPreviousData,
        queryKey: ["vendor_quote_requests_count", id],
        queryFn: async ({ signal }) => {
            const { data } = await API.get(url, {
                signal,
            });
            return data;
        },
        ...options,
    });

    return {
        data,
        isLoading,
        isPlaceholderData,
        isError,
        error,
    };
};

const useQuoteRequestDetails = (
    id: number | undefined,
    options?: UseQueryOptionsType
): UseQueryReturnType<ListingQuoteRequestType> => {
    const url = `${API_URL.BUSINESS_QUOTE}/${id}/`;

    const { isLoading, isError, data, error, isPlaceholderData } = useQuery({
        placeholderData: keepPreviousData,
        queryKey: ["vendor_quote_requests_details", id],
        queryFn: async ({ signal }) => {
            const { data } = await API.get(url, {
                signal,
            });
            return data;
        },
        retry(failureCount, error) {
            if ((error as AxiosError).response?.status === 404) return false;
            return failureCount < 3;
        },
        ...options,
    });

    return {
        data,
        isLoading,
        isPlaceholderData,
        isError,
        error,
    };
};

export { useQuoteRequests, useQuoteRequestsCount, useQuoteRequestDetails };
