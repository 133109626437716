/* eslint-disable react/no-unstable-nested-components */
import { ReactElement, useState, useEffect } from "react";
import { message, Table, Tooltip, Button, Typography } from "antd";
import { DoubleArrowUp, TripleArrowUp, ArrowUp } from "rdhq-icons";
import { CurrentRaceType, RaceReportListing, RaceReportType } from "app/store/races/races.types";
import { IUser } from "app/store/types/user.types";
import { useHasListingCredits } from "app/hooks/useHasListingCredits";
import SkipLogo from "assets/skip_logo.png";
import AnimatedPending from "app/components/elements/animated/animated_pending";
import PUBLIC_SITE_URL from "app/constants/public_site_urls";
import User from "app/utils/user";
import userSlice from "app/store/user/user.slice";
import modalsSlice from "app/store/modals/modals.slice";

import "./index.scss";
import { trimLink } from "app/utils/helpers";
import { obscureData } from "app/utils/helpers/obscure_data";
import BlurredImage from "app/components/elements/wrappers/blurred_image";

type RaceCalType = "Found" | "Pending" | "Submitted";
type RaceReport = Partial<RaceReportType> & {
    currentRace: Partial<CurrentRaceType>;
};

const RaceReport = ({
    listings,
    status,
    date_submitted,
    currentRace,
    setShowModal,
}: RaceReport & {
    setShowModal: (val: boolean) => void;
}): ReactElement => {
    const { user } = userSlice((state) => state);
    const UserModule = User(user as IUser);
    const userHasListingCredits = useHasListingCredits();
    const [listingImageLoaded, setListingImageLoaded] = useState<boolean>(false);
    const {
        toggleMemberFeatureListReportModal,
        toggleMembersProgramModal,
        toggleNoListingCreditsModal,
    } = modalsSlice((state) => state);

    const filteredListingReports = (
        listings_list: RaceReportListing[],
        reportType: RaceCalType
    ): RaceReportListing[] =>
        listings_list &&
        listings_list.filter((item: RaceReportListing) => item.status === reportType);

    // Race calendar status
    const renderRaceCalendarStatus = (listing: RaceReportListing, type: RaceCalType) => {
        if (type === "Found" && listing.confirmed) {
            return (
                <span>
                    <a
                        href={listing.url || listing.calendar.base_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="link"
                    >
                        View listing
                    </a>
                </span>
            );
        }

        if (type === "Submitted" && listing.confirmed && listing.url) {
            return (
                <span>
                    <a
                        href={listing.url || listing.calendar.base_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="link"
                    >
                        View listing
                    </a>
                </span>
            );
        }

        if (
            (type === "Submitted" && !listing.url) ||
            (type === "Submitted" && !listing.confirmed)
        ) {
            return (
                <Tooltip
                    title={
                        <span>
                            There is no available link for this listing.{" "}
                            <a
                                href="https://help.racedirectorshq.com/en/articles/8890940-i-can-t-see-a-view-listing-link-next-to-a-race-calendar-listing-what-does-that-mean"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="link-inline"
                            >
                                Learn more
                            </a>
                        </span>
                    }
                    placement="right"
                >
                    <span>
                        <a
                            href={listing.url || listing.calendar.base_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="link link--disabled"
                        >
                            View listing
                        </a>
                    </span>
                </Tooltip>
            );
        }

        if (type === "Pending" && status === "Pending") return <AnimatedPending />;

        return null;
    };

    const renderSEOBenefit = (rating: number) => {
        if (rating < 40) {
            return (
                <span className="race-marketer__listing-list-benefit">
                    <span>
                        <ArrowUp />
                    </span>
                    Moderate
                </span>
            );
        }

        if (rating < 70)
            return (
                <span className="race-marketer__listing-list-benefit">
                    <span>
                        <DoubleArrowUp />
                    </span>
                    Strong
                </span>
            );

        if (rating >= 70)
            return (
                <span className="race-marketer__listing-list-benefit">
                    <span>
                        <TripleArrowUp />
                    </span>
                    Very strong
                </span>
            );

        return null;
    };

    const renderRaceCalendarsHeader = (type: RaceCalType): string => {
        switch (type) {
            case "Found":
                return `Your race was found on
                ${filteredListingReports(listings as RaceReportListing[], type).length}
                race calendars:`;
            case "Pending":
                switch (status) {
                    case "Draft":
                        return `Your race could also be listed on ${
                            filteredListingReports(listings as RaceReportListing[], type).length
                        } more race calendars:`;
                    case "Pending":
                        return `Your race is queued for listing on these
                        ${filteredListingReports(listings as RaceReportListing[], type).length} race
                        calendars:`;
                    default:
                        return "";
                }
            case "Submitted":
                return `Race submitted to
                        ${filteredListingReports(listings as RaceReportListing[], type).length} race
                        calendars`;
            default:
                return "";
        }
    };

    const renderRaceCalendars = (type: RaceCalType): JSX.Element | null => {
        const obscured = type === "Pending" && status === "Draft";

        const columns = [
            {
                title: "Calendar Name",
                dataIndex: ["calendar", "name"],
                key: "name",
                // width: 300,
                render: (text: string, record: RaceReportListing) => (
                    <span className="race-marketer__listing-list-title">
                        <div
                            className={`race-marketer__listing-list-title-wrap ${
                                obscured ? "race-marketer__listing-list-title-wrap--obscured" : ""
                            }`}
                        >
                            <div className="race-marketer__listings-list-logo">
                                <BlurredImage condition={!obscured}>
                                    {record.calendar.logo ? (
                                        <img
                                            className={listingImageLoaded ? "hasLoaded" : ""}
                                            onLoad={() => setListingImageLoaded(true)}
                                            src={record.calendar.logo}
                                            alt={record.calendar.name}
                                        />
                                    ) : (
                                        <img className="hasLoaded" src={SkipLogo} alt="no logo" />
                                    )}
                                </BlurredImage>
                            </div>

                            <div className="race-marketer__listing-list-title-text">
                                {obscured
                                    ? obscureData(record.calendar.name, "text")
                                    : record.calendar.name}
                            </div>
                        </div>
                    </span>
                ),
            },
            {
                title: () => (
                    <Tooltip
                        placement="top"
                        title="Whether the calendar for that listing is national, regional or local "
                    >
                        <div className="inline tooltip-underline">Reach</div>
                    </Tooltip>
                ),
                dataIndex: ["calendar", "reach"],
                key: "reach",
                width: 175,
                render: (text: string) => (
                    <span
                        style={{
                            textTransform: "capitalize",
                        }}
                    >
                        {text}
                    </span>
                ),
            },
            {
                title: () => (
                    <Tooltip
                        title="An estimate of the local traffic the calendar for that listing receives "
                        placement="bottom"
                    >
                        <div className="inline tooltip-underline">Monthly traffic</div>
                    </Tooltip>
                ),
                dataIndex: ["calendar", "monthly_traffic_display"],
                key: "monthly_traffic_display",
                width: 175,
                align: "right",
                render: (text: string) => (
                    <span
                        style={{
                            textTransform: "capitalize",
                        }}
                    >
                        {text}
                    </span>
                ),
            },
            {
                title: () => (
                    <Tooltip
                        placement="top"
                        title="The SEO benefit this calendar confers. This is based on a 1-100 score. "
                    >
                        <div className="inline tooltip-underline">SEO Benefit</div>
                    </Tooltip>
                ),
                dataIndex: ["calendar", "domain_rating"],
                key: "benefit",
                width: 175,
                render: (rating: number) => renderSEOBenefit(rating),
            },
            {
                title: "",
                dataIndex: "status",
                key: "status",
                width: 150,
                render: (status: RaceCalType, record: RaceReportListing) =>
                    renderRaceCalendarStatus(record, status),
            },
        ];

        const listingReportLength = filteredListingReports(
            listings as RaceReportListing[],
            type
        ).length;

        if (type === "Found" && listingReportLength === 0)
            return (
                <div className="race-marketer__listings-error">
                    <Typography.Title level={4}>{renderRaceCalendarsHeader(type)}</Typography.Title>
                    <div className="race-marketer__listings-error-content">
                        <Typography.Paragraph>
                            We couldn’t find any listings for your race in any of the calendars we
                            searched.
                        </Typography.Paragraph>
                    </div>
                </div>
            );

        if (listingReportLength !== 0) {
            return (
                <div>
                    <Typography.Title level={4} className="race-marketer__listings-heading">
                        {renderRaceCalendarsHeader(type)}
                    </Typography.Title>
                    <div className="race-marketer__listings-wrap">
                        <Table
                            pagination={false}
                            dataSource={filteredListingReports(
                                listings as RaceReportListing[],
                                type
                            )}
                            columns={columns as any}
                            scroll={{ x: true }}
                            rowKey="uuid"
                        />
                    </div>

                    {type === "Pending" && status === "Draft" && (
                        <div className="race-marketer__table-cta">
                            <Button
                                type="primary"
                                onClick={() => {
                                    if (userHasListingCredits) {
                                        setShowModal(true);
                                    } else if (UserModule.isPremiumUser()) {
                                        toggleNoListingCreditsModal();
                                    } else {
                                        toggleMemberFeatureListReportModal();
                                    }
                                }}
                            >
                                List race on these{" "}
                                {
                                    filteredListingReports(listings as RaceReportListing[], type)
                                        .length
                                }{" "}
                                race calendars...
                            </Button>
                        </div>
                    )}
                </div>
            );
        }

        return null;
    };

    const renderFoundSection = (): JSX.Element | null => {
        if (status === "Draft" && !date_submitted && currentRace?.type === "past") {
            return (
                <div className="race-marketer__past-race-content">
                    <Typography.Paragraph>
                        You cannot submit a past race for listing. To submit another race or a new
                        edition for this race, please{" "}
                        <span
                            onClick={toggleMembersProgramModal}
                            role="presentation"
                            className="link"
                        >
                            add a race first
                        </span>
                        .
                    </Typography.Paragraph>
                </div>
            );
        }

        return <>{renderRaceCalendars("Found")}</>;
    };

    const renderPendingOrCompletedSection = (): JSX.Element | null => (
        <div className="race-marketer__report-group">
            {renderRaceCalendars("Submitted") && (
                <div className="race-marketer__report-item race-marketer__report-item--widget">
                    {renderRaceCalendars("Submitted")}
                    {status === "Completed" && (
                        <div className="race-marketer__pending-completed-message">
                            <Typography.Text>
                                <Typography.Text strong>
                                    Should this race be listed on a calendar we missed?
                                </Typography.Text>{" "}
                                <a
                                    href={PUBLIC_SITE_URL.CONTACT}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="link-secondary"
                                >
                                    Let us know
                                </a>{" "}
                                and we will gladly add it for you.
                            </Typography.Text>
                        </div>
                    )}
                </div>
            )}

            {renderRaceCalendars("Pending") && (
                <div className="race-marketer__report-item race-marketer__report-item--widget">
                    {renderRaceCalendars("Pending")}
                </div>
            )}
        </div>
    );

    return (
        <div className="race-marketer__report">
            <div className="race-marketer__report-item race-marketer__report-item--widget">
                {renderFoundSection()}
            </div>
            {renderPendingOrCompletedSection()}
        </div>
    );
};

export default RaceReport;
