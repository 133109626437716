import { ReactElement, ReactNode, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Tooltip, Typography, Rate } from "antd";
import { CheckOutlined } from "rdhq-icons";
import { ListingStatus, ListingType } from "app/types";
import { CrownOutlined, PenOutlined } from "assets";
import PUBLIC_SITE_URL from "app/constants/public_site_urls";
import STORAGE_CONSTANTS from "app/constants/storage";
import Storage from "app/utils/storage/local";
import listingsSlice from "app/store/listings/listings.slice";
import userSlice from "app/store/user/user.slice";
import URL from "app/constants/route_urls";
import "./listing_card.scss";

const canEdit = {
    active: true,
    pending: false,
    suspended: false,
    unavailable: false,
    inaccessible: false,
    paused: false,
    retired: false,
};

const editBtnTooltip = {
    active: "Edit listing",
    pending: "You cannot edit a pending listing",
    suspended: "You cannot edit a suspended listing. To reinstate your listing please contact us.",
    unavailable: "",
    inaccessible: "",
    paused: "",
    retired: "",
};

const canSelect = {
    active: true,
    pending: true,
    suspended: false,
    unavailable: false,
    inaccessible: false,
    paused: false,
    retired: false,
};

const selectBtnTooltip: { [key: string]: string } = {
    published: "Make this your current working listing",
    pending: "Make this your current working listing",
    suspended:
        "You cannot select a suspended listing. To reinstate your listing please contact us.",
    unavailable: "",
    inaccessible: "",
    paused: "",
    retired: "",
};

const StatusTooltip: { [key: string]: string | ReactNode } = {
    Pending: "Your listing is under review.",
    Suspended: (
        <span>
            Your listing has been suspended. To reinstate it{" "}
            <a
                href={`${PUBLIC_SITE_URL.CONTACT}`}
                style={{ fontWeight: "500", color: "#1bc537" }}
                rel="noreferrer"
                target="_blank"
            >
                contact us.
            </a>
        </span>
    ),
};

const ListingCard = ({
    pk,
    title,
    thumbnail_image,
    display_status,
    rating,
    is_premium,
    showing_in,
    plan,
    categories,
    status,
}: ListingType): ReactElement => {
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const cardRef = useRef<HTMLDivElement>(null);
    const imageRef = useRef<HTMLImageElement>(null);
    const { currentListingSelection, setCurrentListing } = listingsSlice((state) => state);
    const user = userSlice((state) => state.user);
    const isActive = currentListingSelection === pk;

    const currentListing = (): JSX.Element | null => {
        if (!isActive) {
            return (
                <Tooltip title={selectBtnTooltip[status]} placement="bottom">
                    <Button
                        className="ant-btn-primary-outline"
                        size="middle"
                        type="default"
                        disabled={!canSelect[status]}
                        onClick={() => {
                            setCurrentListing(pk);
                            Storage.set(
                                `${STORAGE_CONSTANTS.currentListing}.${user?.email}`,
                                pk.toString()
                            );
                        }}
                    >
                        Select
                    </Button>
                </Tooltip>
            );
        }
        if (isActive) {
            return (
                <Button
                    className="ant-custom-btn-outline-green pointer-events-none"
                    icon={<CheckOutlined />}
                >
                    Selected
                </Button>
            );
        }
        return null;
    };

    return (
        <div ref={cardRef} className={`listing-card ${isActive ? "isActive" : ""}`}>
            <div className="listing-card__top">
                <div className="listing-card__logo">
                    <div className="listing-card__logo-wrap">
                        {thumbnail_image && (
                            <img
                                ref={imageRef}
                                alt=""
                                src={thumbnail_image as string}
                                loading="lazy"
                                onLoad={() => setLoading(true)}
                                className={`load-image ${loading ? "image-loaded" : ""}`}
                            />
                        )}
                    </div>
                    {is_premium && (
                        <div className="listing-card__premium">
                            <Tooltip title="Premium listing">
                                <CrownOutlined
                                    style={{ color: "#ffffff", width: "20px", height: "20px" }}
                                />
                            </Tooltip>
                        </div>
                    )}
                </div>
                <div className="listing-card__title">
                    <Typography.Title level={3} className={`${isActive ? "isActive" : ""}`}>
                        {title}
                    </Typography.Title>
                    <Typography.Paragraph style={{ minHeight: "2.3rem" }}>
                        {categories?.at(0)?.name}
                    </Typography.Paragraph>
                </div>
            </div>
            <div className="listing-card__content">
                <div className="listing-card__content-item row">
                    <Typography.Text strong className="listing-card__content-item-label">
                        Status:
                    </Typography.Text>
                    <Tooltip title={StatusTooltip[display_status]}>
                        <Typography.Text
                            type="secondary"
                            className={`listing-card__status ${display_status.toLowerCase()}`}
                        >
                            <span className={status} />
                            {display_status}
                        </Typography.Text>
                    </Tooltip>
                </div>
                <div className="listing-card__content-item row">
                    <Typography.Text strong className="listing-card__content-item-label">
                        Rating:
                    </Typography.Text>
                    <Typography.Text type="secondary">
                        <span>{rating}</span>
                        <Rate allowHalf disabled value={+rating} />
                    </Typography.Text>
                </div>
                <div className="listing-card__content-item row">
                    <Typography.Text strong className="listing-card__content-item-label">
                        Plan:
                    </Typography.Text>
                    <Typography.Text type="secondary">{plan.display_name}</Typography.Text>
                </div>
                {status !== ListingStatus.PENDING && (
                    <div className="listing-card__content-item row">
                        <Typography.Text
                            strong
                            className="listing-card__content-item-label"
                            style={{ alignSelf: "flex-start" }}
                        >
                            Showing in:
                        </Typography.Text>
                        <Typography.Text type="secondary" className="listing-card__country-wrap">
                            <span>{showing_in.join(", ")}</span>
                        </Typography.Text>
                    </div>
                )}
            </div>
            <div className="listing-card__cta">
                <div className="listing-card__cta-item">{currentListing()}</div>
                <div className="listin-card__cta-item">
                    <Tooltip title={editBtnTooltip[status]} placement="bottom">
                        <Button
                            className="ant-custom-btn-icon"
                            disabled={!canEdit[status]}
                            onClick={() => navigate(`${URL.BUSINESS_LISTINGS}${pk}/edit/`)}
                        >
                            <PenOutlined />
                        </Button>
                    </Tooltip>
                </div>
            </div>
        </div>
    );
};

export default ListingCard;
