import { useEffect } from "react";
import { Outlet, useLocation, Navigate } from "react-router-dom";
import { _isEmpty } from "app/utils/helpers";
import { IUser } from "app/store/types/user.types";
import { BUSINESS_SIDE_MENU } from "app/lib/navigation/navigation";
import { useQuoteRequestsCount } from "app/utils/api/queries/quote_requests.query";
import { ListingType } from "app/types";
import { useVendorListings } from "app/utils/api/queries";
import GlobalModals from "app/components/modules/global_modals";
import URL from "app/constants/route_urls";
import LoadingScreen from "app/components/elements/loading_screen";
import TopNavigation from "app/components/modules/navigation/top_navigation";
import ListingSelect from "app/components/modules/listing_select/listing_select";
import SideNavigation from "app/components/modules/navigation/side_navigation";
import userSlice from "app/store/user/user.slice";
import modalsSlice from "app/store/modals/modals.slice";
import listingsSlice from "app/store/listings/listings.slice";
import User from "app/utils/user";
import STORAGE_CONSTANTS from "app/constants/storage";
import MISC_CONSTANTS from "app/constants/misc";
import Storage from "app/utils/storage/local";
import "./index.scss";

const VendorLayout = () => {
    const location = useLocation();
    const { isAuthenticated, user, getUser } = userSlice();
    const { navbar, toggleNavbar } = modalsSlice((state) => state);
    const { currentListingSelection, listing, setListingsCount } = listingsSlice((state) => state);
    const { data } = useVendorListings();
    const { data: quoteRequestsCount } = useQuoteRequestsCount(currentListingSelection, false, {
        enabled: currentListingSelection !== undefined,
    });
    const UserModule = User(user as IUser);
    const currentDashboard = Storage.get(`${STORAGE_CONSTANTS.currentDashboard}`);

    const prepareBusinessItems = BUSINESS_SIDE_MENU.map((menu) => ({
        ...menu,
        children: menu.children.map((child) => {
            if (child?.count) return { ...child, count: quoteRequestsCount };
            return child;
        }),
    }));

    // Add current dashboard to local storage
    useEffect(() => {
        if (user.pk && currentDashboard !== MISC_CONSTANTS.BUSINESS_DASHBOARD) {
            Storage.set(`${STORAGE_CONSTANTS.currentDashboard}`, MISC_CONSTANTS.BUSINESS_DASHBOARD);
        }
    }, [currentDashboard, user.pk]);

    useEffect(() => {
        setListingsCount(data?.length as number);
    }, [data?.length, setListingsCount]);

    useEffect(() => {
        if (isAuthenticated && _isEmpty(user)) getUser();
    }, [user, isAuthenticated, getUser]);

    if (_isEmpty(user)) return <LoadingScreen type="layout" />;
    if (!isAuthenticated) return <Outlet />;
    if (location?.pathname === URL.BUSINESS_DASHBOARD || location?.pathname === "/business")
        return <Navigate to={URL.BUSINESS_LISTINGS} />;

    return (
        <main className={`layout ${navbar.isToggled ? "isToggled" : ""}`}>
            <div
                role="presentation"
                className={`layout__overlay ${navbar.isToggled ? "isVisible" : ""}`}
                onClick={toggleNavbar}
            />
            <GlobalModals />
            <TopNavigation
                user={user as IUser}
                isNavbarToggled={navbar.isToggled}
                toggleNavbar={toggleNavbar}
                selectionTool={<ListingSelect />}
                listing={listing as ListingType}
            />
            <SideNavigation
                user={user as IUser}
                isNavbarToggled={navbar.isToggled}
                toggleNavbar={toggleNavbar}
                menuLinks={prepareBusinessItems}
            />
            <div
                className={`main-content ${navbar.isToggled ? "isToggled" : ""}`}
                style={{ backgroundColor: "#f9fafc", minHeight: "100vh" }}
            >
                <Outlet />
            </div>
        </main>
    );
};

export default VendorLayout;
