import PageWithLoader from "app/hoc/page_with_loader";
import "./index.scss";
import { Button, message, Rate, Select, Typography } from "antd";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useUserListingReviews, useListingSummary, useVendorListings } from "app/utils/api/queries";
import TextArea from "antd/es/input/TextArea";
import { ChangeEvent, useEffect, useState } from "react";
import URL from "app/constants/route_urls";
import userSlice from "app/store/user/user.slice";
import { SubmitHandler, useForm } from "react-hook-form";
import { useAddReview } from "app/utils/api/mutations/listing.mutation";
import { yupResolver } from "@hookform/resolvers/yup";
import { addReviewValidation } from "app/lib/validation_schemas/add_review_validation_schema";

type Inputs = {
    content: string;
    rating: number;
};

const AddReview = () => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const navigate = useNavigate();
    const [checkingPermission, setCheckingPermission] = useState(true);

    const listingPk = query.get("listing");

    const {
        data: listing,
        isLoading: listingLoading,
        error,
        isError,
    } = useListingSummary(listingPk || "", {
        enabled: !!listingPk,
    });
    const { user } = userSlice();
    const { data: userListings, isLoading: userListingsLoading } = useVendorListings({
        enabled: !!listingPk,
    });
    const { data: myReviews, isLoading: myReviewsLoading } = useUserListingReviews(
        {
            pk: listingPk || "",
            userPk: user.pk || "",
        },
        {
            enabled: !!user.pk && !!listingPk,
        }
    );
    const { mutate: addReview, isPending: submittingReview } = useAddReview({
        onSuccess: () => {
            message.success("Review submitted successfully");
            navigate(URL.RACES);
        },
    });

    // Navigate to 404 if listingPk is not provided

    const isLoading = listingLoading || userListingsLoading || !listing || myReviewsLoading;

    // Handle 404 error
    useEffect(() => {
        if (isError && error?.response.status === 404) navigate("/404");
    }, [error?.response.status, isError, navigate]);

    // Check user allowed to submit listing
    useEffect(() => {
        // Check if user owns the listing
        if (userListings && listing && userListings?.findIndex((l) => l.pk === listing.pk) !== -1) {
            message.error("You can't submit a review for a listing you own");
            navigate(URL.RACES);
        }

        // Check if user already submitted a review for that listing
        if (listing && myReviews && myReviews?.findIndex((r) => r.author.pk === user.pk) !== -1) {
            message.error(`You have already submitted a review for this ${listing?.title}`);
            navigate(URL.RACES);
        }

        if (!isLoading) {
            setCheckingPermission(false);
        }
    }, [isLoading, listing, myReviews, navigate, user.pk, userListings]);

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        trigger,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(addReviewValidation),
        mode: "onSubmit",
        reValidateMode: "onSubmit",
    });

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        if (listing) {
            addReview({ ...data, listing: listing.pk });
        }
    };

    const onFieldChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setValue(event.target.name as keyof Inputs, event.target.value);
    };

    // Handle page title
    useEffect(() => {
        if (listing?.title) {
            document.title = `Review ${listing.title}`;
        }
    }, [listing?.title]);

    if (!listingPk) return <Navigate to="/404" />;

    return (
        <PageWithLoader isLoading={isLoading || checkingPermission}>
            <div className="add-review">
                <Typography.Title level={1}>Your review for {listing?.title}</Typography.Title>
                <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                    Fields marked with (<span className="add-edit-race__required">*</span>) are
                    required
                </Typography.Text>

                <form className="add-review__form" onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <Typography.Text className="ant-label" strong>
                            <span>Your comments</span> <span className="ant-label-required">*</span>
                        </Typography.Text>

                        <TextArea
                            rows={6}
                            status={errors.content ? "error" : ""}
                            value={watch("content")}
                            {...register("content")}
                            onChange={onFieldChange}
                        />

                        {errors.content && (
                            <Typography.Text className="ant-error-label">
                                {errors.content.message}
                            </Typography.Text>
                        )}
                    </div>

                    <div>
                        <Typography.Text className="ant-label" strong>
                            <span>Your rating</span> <span className="ant-label-required">*</span>
                        </Typography.Text>

                        <div>
                            <Select
                                style={{ width: "100%" }}
                                status={errors.rating ? "error" : ""}
                                options={[
                                    { label: <Rate value={1} disabled />, value: 1 },
                                    { label: <Rate value={2} disabled />, value: 2 },
                                    { label: <Rate value={3} disabled />, value: 3 },
                                    { label: <Rate value={4} disabled />, value: 4 },
                                    { label: <Rate value={5} disabled />, value: 5 },
                                ]}
                                value={watch("rating")}
                                {...register("rating")}
                                onChange={(value) => setValue("rating", value)}
                            />

                            {errors.rating && (
                                <Typography.Text className="ant-error-label">
                                    {errors.rating.message}
                                </Typography.Text>
                            )}
                        </div>
                    </div>

                    <div className="add-review__form-cta">
                        <Button
                            type="primary"
                            loading={submittingReview}
                            disabled={false}
                            className="responsive-cta"
                            htmlType="submit"
                        >
                            <span>Submit</span>
                        </Button>
                    </div>
                </form>
            </div>
        </PageWithLoader>
    );
};

export default AddReview;
