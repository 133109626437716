import { create } from "zustand";
import { devtools } from "zustand/middleware";

type IListingsType = {
    currentListingSelection: number | undefined;
    listing: any;
    listingsCount: number | undefined;
    setCurrentListing: (arg: number) => void;
    setListingsCount: (arg: number) => void;
    setListing: (arg: any) => void;
    flushListingsStore: () => void;
};

const initialState = {
    currentListingSelection: undefined,
    listing: undefined,
    listingsCount: undefined,
};

const listingsSlice = create<IListingsType>()(
    devtools(
        (set) => ({
            setCurrentListing: (pk: number): void => {
                set({ currentListingSelection: pk }, false, "listingsSlice/setCurrentListing");
            },
            setListingsCount: (count: number): void => {
                set({ listingsCount: count }, false, "listingsSlice/setListingsCount");
            },
            setListing: (listing: any): void => {
                set({ listing }, false, "listingsSlice/setListing");
            },
            flushListingsStore: () => {
                set({ ...initialState });
            },
            ...initialState,
        }),
        { name: "Listings slice", enabled: !import.meta.env.PROD }
    )
);

export default listingsSlice;
