import React, { useRef, useEffect, useState } from "react";

// Define the props interface
interface TextToImageProps {
    text: string;
    fontSize: string;
    fontWeight: string;
    font: string;
    color: string;
    blurAmount: number;
    horizontalPadding: number;
    verticalPadding: number;
}

const BlurredText: React.FC<TextToImageProps> = ({
    text,
    fontSize,
    fontWeight,
    font,
    color,
    blurAmount,
    horizontalPadding,
    verticalPadding,
}) => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const [dimensions, setDimensions] = useState<{ width: number; height: number }>({
        width: 0,
        height: 0,
    });

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext("2d");
        if (!ctx) return;

        // Construct the font string with weight, size, and family
        const fontString = `${fontWeight} ${fontSize} ${font}`;
        ctx.font = fontString;
        ctx.fillStyle = color;

        // Measure text dimensions
        const textMetrics = ctx.measureText(text);
        const textWidth = textMetrics.width;
        const textHeight = parseInt(fontSize, 10);

        // Add horizontal padding
        const canvasWidth = textWidth + 2 * horizontalPadding;

        // Add vertical padding
        const canvasHeight = textHeight + 2 * verticalPadding;

        // Set canvas dimensions
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;

        // Center text horizontally and vertically
        const x = canvasWidth / 2;
        const y = canvasHeight / 2;

        // Clear canvas before drawing
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Apply blur effect
        ctx.filter = `blur(${blurAmount}px)`;

        // Draw text with horizontal padding
        ctx.font = fontString;
        ctx.fillStyle = color;
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillText(text, x, y);

        // Reset filter after drawing
        ctx.filter = "none";
    }, [text, fontSize, fontWeight, font, color, blurAmount, horizontalPadding, verticalPadding]);

    return <canvas ref={canvasRef} width={dimensions.width} height={dimensions.height} />;
};

export default BlurredText;
