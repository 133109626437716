import LoadingScreen from "app/components/elements/loading_screen";
import API_URL from "app/constants/api_urls";
import { useRacesWidget } from "app/hooks/useRacesWidget";
import API from "app/utils/api/axios";
import { AxiosError } from "axios";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const RaceReviews = () => {
    const params = useParams();
    const navigate = useNavigate();

    const { handleSelectRaceString, stringifyRaceObject } = useRacesWidget();

    // Select specified race
    useEffect(() => {
        if (params.pk) {
            API.get(`${API_URL.RACE_DETAILS}${params.pk}/`)
                .then((response) => {
                    handleSelectRaceString(stringifyRaceObject(response.data));
                })
                .catch((error: AxiosError) => {
                    if (error.response?.status === 404) {
                        navigate("/404");
                    }
                });
        }
    }, [handleSelectRaceString, navigate, params.pk, stringifyRaceObject]);

    // Redirect to review url
    useEffect(() => {
        if (params.review_uuid) {
            API.get(`${API_URL.REVIEWS}${params.review_uuid}`)
                .then((response) => {
                    window.location.href = response.data.url;
                })
                .catch((error: AxiosError) => {
                    if (error.response?.status === 404) {
                        navigate("/404");
                    }
                });
        }
    }, [navigate, params.review_uuid]);

    // TODO: allow the user to go back to /races/upcoming/ instead if the redirect route

    return <LoadingScreen type="page" />;
};

export default RaceReviews;
