import { useEffect } from "react";
import userSlice from "app/store/user/user.slice";

const Intercom = (): null => {
    const { user } = userSlice((state) => state);

    useEffect(() => {
        if (window && window.Intercom && window.gtmInjected) {
            window.Intercom("boot", {
                api_base: import.meta.env.VITE_INTERCOM_APP_URL,
                app_id: import.meta.env.VITE_INTERCOM_APP_ID,
                name: user?.first_name,
                email: user?.email,
                user_id: user?.username,
            });
        }
    }, [user?.first_name, user?.email, user?.username]);
    return null;
};

export default Intercom;
