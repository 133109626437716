import { ReactElement } from "react";
import { Link, useLocation } from "react-router-dom";
import { ExpandSidebarOutlined, CollapseSidebarOutlined } from "rdhq-icons";
import { Tooltip, Typography } from "antd";
import { IUser } from "app/store/types/user.types";
import { SideNavChild, SideNavParent, SideNavMenuItems } from "app/lib/navigation/types";
import { _isEmpty } from "app/utils/helpers";
import { useWindowSize } from "app/hooks";
import logo from "assets/logo.svg";
import secondaryLogo from "assets/logo_white.svg";
import URL from "app/constants/route_urls";
import "./index.scss";

const RESPONSIVE_SIZE = 992;

const SideNavigation = ({
    user,
    isNavbarToggled,
    menuLinks,
    toggleNavbar,
}: {
    user: IUser;
    isNavbarToggled: boolean;
    menuLinks: SideNavMenuItems;
    toggleNavbar: () => void;
}): ReactElement => {
    const location = useLocation();
    const isRouteActive = (link: string): boolean => location && location.pathname.includes(link);
    const isBusinessDashboard = location?.pathname.includes("business");
    const { width } = useWindowSize();
    const userApps = user?.apps.map((app) => app.slug);

    // Display nav children
    const renderGroupChildren = (children: SideNavChild[]): JSX.Element[] =>
        children &&
        children.map((child: SideNavChild) => {
            if (child.external) {
                return (
                    <Tooltip
                        title={!isNavbarToggled ? child.label : ""}
                        placement="right"
                        key={child.key}
                    >
                        <li
                            className={`side-navigation__list-item ${
                                isRouteActive(child.route) ? "nav-active" : ""
                            } ${!child.current ? "notCurrent" : ""}`}
                        >
                            <a
                                className={`link ${isNavbarToggled ? "toggled" : ""} `}
                                href={child.href}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {child.img()}
                                <span
                                    className={`sidespan ${isNavbarToggled ? "toggled" : ""}`}
                                    key={child.key}
                                >
                                    {child.title}
                                </span>
                            </a>
                        </li>
                    </Tooltip>
                );
            }

            if (!userApps.includes(child.type) && !child.type.includes("business"))
                return <span key={child.key} />;

            return (
                <Tooltip
                    title={!isNavbarToggled ? child.label : ""}
                    placement="right"
                    key={child.key}
                >
                    <li
                        className={`side-navigation__list-item ${
                            isRouteActive(child.route)
                                ? `nav-active ${isBusinessDashboard ? "isBusiness" : ""}`
                                : ""
                        } ${!child.current ? "notCurrent" : ""}`}
                    >
                        <Link
                            className={`link ${isNavbarToggled ? "toggled" : ""} `}
                            to={child.href}
                            onClick={() => {
                                if (width <= RESPONSIVE_SIZE) toggleNavbar();
                            }}
                        >
                            {child.img()}
                            <span className={`sidespan ${isNavbarToggled ? "toggled" : ""}`}>
                                {child.title}{" "}
                                {child.count ? (
                                    <b className="sidespan__count" data-count={child.count}>
                                        <span>{child.count}</span>
                                    </b>
                                ) : null}
                                {child.isNew && (
                                    <span className="side-navigation__list-item-tag">new</span>
                                )}
                            </span>
                        </Link>
                    </li>
                </Tooltip>
            );
        });

    // Display nav parents
    const renderGroupParents = (parents: SideNavMenuItems): (JSX.Element | null)[] =>
        parents.map((item: SideNavParent) => {
            if ((!_isEmpty(user) && userApps.includes(item.type)) || item.type === "business") {
                return (
                    <div
                        className={`side-navigation__link-grp ${
                            isBusinessDashboard ? "isBusiness" : ""
                        }`}
                        key={item.id}
                    >
                        {item.title && isNavbarToggled && (
                            <Typography.Title level={5} className="toggled">
                                {item.title}
                            </Typography.Title>
                        )}
                        <ul
                            className={`side-navigation__list ${
                                isNavbarToggled ? "isToggled" : ""
                            }`}
                        >
                            {!_isEmpty(item) && renderGroupChildren(item.children)}
                        </ul>
                    </div>
                );
            }
            if (!_isEmpty(user) && item.type === "external") {
                return (
                    <div className="side-navigation__link-grp" key={item.id}>
                        {item.title && isNavbarToggled && (
                            <Typography.Title level={5} className="toggled">
                                {item.title}
                            </Typography.Title>
                        )}
                        <ul
                            className={`side-navigation__list ${
                                isNavbarToggled ? "isToggled" : ""
                            }`}
                        >
                            {!_isEmpty(item) && renderGroupChildren(item.children)}
                        </ul>
                    </div>
                );
            }
            return null;
        });

    // Display nav items
    const renderSideNavItems = (): (JSX.Element | null)[] => renderGroupParents(menuLinks);

    return (
        <div
            className={`side-navigation ${isNavbarToggled ? "toggled" : ""} ${
                isBusinessDashboard ? "isBusiness" : ""
            }`}
        >
            <div className={`side-navigation__top-wrap ${isNavbarToggled ? "isToggled" : ""}`}>
                <div
                    className={`side-navigation__hamburger ${isNavbarToggled && "isToggled"}`}
                    onClick={toggleNavbar}
                    role="presentation"
                >
                    <ExpandSidebarOutlined color="#ffffff" />
                </div>
                {isNavbarToggled && (
                    <div className="side-navigation__toggled-wrap">
                        <Link
                            to={isBusinessDashboard ? URL.BUSINESS_DASHBOARD : "/"}
                            className="side-navigation__logo-link"
                        >
                            <img
                                className="side-navigation__full-logo"
                                src={isBusinessDashboard ? secondaryLogo : logo}
                                alt="logo"
                            />
                        </Link>
                        <div />
                        <CollapseSidebarOutlined
                            className={`side-navigation__collapse-logo ${
                                isNavbarToggled ? "toggled-active" : ""
                            }`}
                            onClick={toggleNavbar}
                            color="#ffffff"
                        />
                    </div>
                )}
            </div>

            <header
                className={`side-navigation__links-wrap ${
                    isNavbarToggled ? "toggled" : "notToggled"
                }`}
            >
                {renderSideNavItems()}
            </header>
        </div>
    );
};

export default SideNavigation;
