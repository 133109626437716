import { ReactElement, useState, useRef } from "react";
import { Typography, Modal, Button, message } from "antd";
import { useVendorBadges } from "app/utils/api/queries";
import { BadgeType } from "app/types";
import { _isEmpty } from "app/utils/helpers";
import VendorBadgeCard from "app/components/elements/cards/vendor_badge_card/vendor_badge_card";
import PageWithLoader from "app/hoc/page_with_loader";
import { useNavigate } from "react-router-dom";
import listingsSlice from "app/store/listings/listings.slice";
import URL from "app/constants/route_urls";
import "./badges.scss";

type ListingData = {
    id: string;
    color: BadgeType["colour"];
    style: BadgeType["style"];
};

const VendorBadges = (): ReactElement => {
    const navigate = useNavigate();
    const textToCopyRef = useRef<HTMLElement>(null);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [currentListingData, setCurrentListingData] = useState<
        Partial<ListingData> & Partial<BadgeType>
    >({});

    const [messageApi, contextHolder] = message.useMessage();
    const { currentListingSelection, listingsCount } = listingsSlice((state) => state);

    const badgesEnabled: boolean = currentListingSelection !== undefined;

    const { data, error, isError, isLoading } = useVendorBadges(currentListingSelection, {
        enabled: badgesEnabled,
    });
    const isBadges = data && data?.length > 1 ? "badges" : "badge";

    const copyToClipboard = () => {
        const textToCopy = textToCopyRef.current?.textContent;
        navigator.clipboard
            .writeText(textToCopy as string)
            .then(() => messageApi.success("Text copied to clipboard"))
            .catch(() => messageApi.error("Failed to copy text"));
    };

    const badgesGroupBy = data?.reduce(
        (acc, badge: BadgeType) => {
            const groupName = badge.grouping;
            acc[groupName] = [...(acc[groupName] || []), badge];
            return acc;
        },
        {} as Record<string, BadgeType[]>
    );

    const badgeTitles: string[] | undefined = badgesGroupBy && Object.keys(badgesGroupBy);

    return (
        <PageWithLoader isLoading={isLoading} error={isError} errorText={error?.message}>
            {contextHolder}
            <div className="vendor-badges">
                <div className="page__header">
                    <Typography.Title level={1}>Badges</Typography.Title>
                    <Typography.Text>
                        A list of badges to enhance your business website authority.
                    </Typography.Text>
                </div>
                {listingsCount && !_isEmpty(data) ? (
                    <div className="page__toolbar">
                        <Typography.Text strong>
                            {data?.length} {isBadges} available
                        </Typography.Text>
                    </div>
                ) : null}

                {_isEmpty(data) && !isLoading ? (
                    <div className="vendor-badges__empty">
                        {!listingsCount ? (
                            <Typography.Text>
                                To view available badges for your business,{" "}
                                <span
                                    role="presentation"
                                    className="link"
                                    onClick={() => navigate(URL.BUSINESS_LISTINGS_ADD)}
                                >
                                    add a business listing
                                </span>{" "}
                                first.
                            </Typography.Text>
                        ) : (
                            <Typography.Text>You have no badges to display.</Typography.Text>
                        )}
                    </div>
                ) : (
                    <div className="vendor-badges__content">
                        {badgeTitles?.map((title: string) => (
                            <div key={title} className="vendor-badges__content-grp">
                                <div className="vendor-badges__content-grp-title">
                                    <Typography.Title level={4}>
                                        <span>{title}</span>
                                    </Typography.Title>
                                </div>
                                <div className="vendor-badges__content-grp-cards">
                                    {badgesGroupBy?.[title]?.map((badge: BadgeType) => (
                                        <VendorBadgeCard
                                            key={badge.uuid}
                                            id={badge.uuid}
                                            color={badge.colour}
                                            image={badge.image_path}
                                            height={badge.recommended_height}
                                            width={badge.recommended_width}
                                            description={badge.short_description}
                                            styling={badge.style}
                                            tier={badge.tier}
                                            openModal={(): void => {
                                                setModalOpen(true);
                                                setCurrentListingData({
                                                    id: badge.uuid,
                                                    style: badge.style,
                                                    color: badge.colour,
                                                    recommended_height: badge.recommended_height,
                                                    recommended_width: badge.recommended_width,
                                                });
                                            }}
                                        />
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                )}

                <Modal
                    centered
                    title="Embed badge on website"
                    open={modalOpen}
                    onCancel={() => {
                        setModalOpen(false);
                        setCurrentListingData({});
                    }}
                    footer={[
                        <Button type="primary" onClick={copyToClipboard}>
                            Copy
                        </Button>,
                    ]}
                    width={480}
                >
                    <Typography.Paragraph style={{ marginBottom: "1rem" }}>
                        To embed this badge on your site, copy the code below and add it wherever
                        you want the badge to appear on your site.
                    </Typography.Paragraph>
                    <div className="vendor-badges__embed-section">
                        <Typography.Text ref={textToCopyRef}>
                            {`<a href="https://www.racedirectorshq.com/listings/${currentListingData.id}/" target="_blank"><img src="https://www.racedirectorshq.com/listings/${currentListingData.id}/badges/${currentListingData.color}/${currentListingData.style}/" width="${currentListingData?.recommended_width}" height="${currentListingData?.recommended_height}"></a>`}
                        </Typography.Text>
                    </div>
                </Modal>
            </div>
        </PageWithLoader>
    );
};

export default VendorBadges;
