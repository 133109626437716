import { useQuery } from "@tanstack/react-query";
import { UseQueryReturnType, UseQueryOptionsType } from "app/utils/api/queries";
import { BadgeType } from "app/types";
import API from "app/utils/api/axios";
import API_URL from "app/constants/api_urls";

const useVendorBadges = (
    id: number | undefined,
    options?: UseQueryOptionsType
): UseQueryReturnType<BadgeType[] | undefined> => {
    const { isPending, isLoading, isError, data, error } = useQuery({
        queryKey: ["vendor_badges", id],
        queryFn: async ({ signal }) => {
            const { data } = await API.get<BadgeType[]>(
                `${API_URL.BUSINESS_LISTINGS}${id}/badges/`,
                { signal }
            );
            return data;
        },
        ...options,
    });

    return {
        data,
        isLoading: isPending && isLoading,
        isError,
        error,
    };
};

export { useVendorBadges };
