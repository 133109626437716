export enum ListingStatus {
    PENDING = "pending",
    PUBLISHED = "active",
    SUSPENDED = "suspended",
    UNAVAILABLE = "unavailable",
    INACCESSIBLE = "inaccessible",
    PAUSED = "paused",
    RETIRED = "retired",
}

export type ListingType = {
    pk: number;
    uuid: string;
    title: string;
    description: string;
    categories: { pk: number; name: string }[];
    countries: number[];
    regions: number[];
    contact_website: string | null;
    contact_email: string | null;
    contact_phone: string | null;
    num_reviews: number;
    thumbnail_image: string | null;
    rating: string;
    url: string;
    tier: number;
    showing_in: string[];
    receive_quotes: boolean;
    is_claimed: boolean;
    is_premium: boolean;
    status: ListingStatus;
    display_status: "Pending" | "Submitted" | "Suspended";
    primary_category: {
        pk: number;
        name: string;
        parent: string;
        has_regions: boolean;
    };
    plan: {
        class: "paid" | "free";
        display_name: string;
        name: "basic" | "premium";
        payment_link: "string;";
        renewal_date: "string" | null;
        tier: number;
        type: "listing";
        upgradeable: boolean;
        default: boolean;
        upgrades_to?: {
            amount: number;
            amount_in_cents: number;
            currency: "usd";
            display_name: string;
            display_price: string;
            id: string;
            name: string;
        };
    };
};

export type ListingQuoteRequestType = {
    pk: number;
    phone: string;
    offer: {
        title: string;
    } | null;
    race: {
        name: string;
        num_participants: number;
        start_date: string;
        location: string;
    } | null;
    listing: {
        pk: number;
        title: string;
    };
    email: string;
    name: string;
    date_received: string | null;
    date_viewed: string | null;
    date_expires: string | null;
    content: string;
    content_redacted?: string;
};

export type ListingReviewType = {
    listing: number;
    author: {
        pk: number;
        username: string;
        email: string;
    };
    content: string;
    rating: number;
    approved: boolean;
    date_created: string;
};
